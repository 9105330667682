<template>
  <div id="app">
    <NavBar  name="收款账号绑定"></NavBar>
    <router-view></router-view>
  </div>

</template>

<script>
// import TopNav from 'muyu-navigation-model/packages/src/NavigationModel/NavigationModel';
// import LeftNav from '@/components/LeftNav';

export default {
  name: 'App',

  data() {
    return {
      navList:[
        {
          path: '/system/account',
          name: '收款账号设置',
          jurisdictionId: '10110100000000000000T1',
        },
      ]
    }
  },
  created() {
    // this.$store.commit('CHANGEROLEID', JSON.parse(window.localStorage.getItem('loginInfo')).role_id.split(',') || []);
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding: 0;
  height: 68px;
}
/deep/ .el-main{
  padding: 0;
}
/deep/ .el-date-editor{
  vertical-align: top;
}
/deep/ .el-input__inner{
  height: 32px;
  border-radius: 0;
  line-height:32px
}
/deep/ .el-range-separator{
  line-height: 24px;

}
</style>
